<template>
  <div class="flex flex-col h-full">
    <sub-order-shipment-reminder
      v-if="!reminderChecked"
      v-model="reminderChecked"
      :sub-order="props.subOrder"
    />
    <template v-else>
      <p class="mb-2 font-serif text-xl text-center text-gray-700 md:text-2xl">
        Agrega el detalle del envío
      </p>
      <form
        class="flex flex-col px-3 h-full text-gray-700 md:px-5"
        @submit="onSubmit"
      >
        <p class="mb-4 text-sm font-light text-center text-gray-700 md:text-base">
          Ingresa la siguiente información
        </p>
        <p class="mb-1 text-sm font-light md:text-base">
          ¿Tienes un link de seguimiento?
        </p>
        <div class="flex items-center mb-3 space-x-2">
          <button
            class="p-1 w-12 h-8 text-sm hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:w-14 md:h-9 md:text-base"
            :class="{ 'bg-lk-green text-white': selectedOption === 'withTrackingUrl',
                      'text-lk-green': selectedOption !== 'withTrackingUrl' }"
            type="button"
            @click="selectedOption = 'withTrackingUrl'"
          >
            Sí
          </button>
          <button
            class="hover: p-1 w-12 h-8 text-sm hover:text-white hover:bg-red-500 rounded-md border border-lk-green hover:border-none md:w-14 md:h-9 md:text-base"
            :class="{ 'bg-red-500 text-white border-none': selectedOption === 'withoutTrackingUrl',
                      'text-lk-green': selectedOption !== 'withoutTrackingUrl' }"
            type="button"
            @click="selectedOption = 'withoutTrackingUrl'"
          >
            No
          </button>
        </div>
        <div
          v-if="selectedOption === 'withTrackingUrl'"
          class="flex flex-col mb-2"
        >
          <p class="mb-1 text-sm font-light md:text-base">
            Link de seguimiento
          </p>
          <Field
            v-model="form.trackingUrl"
            class="p-2 text-xs font-light rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:text-sm"
            placeholder="Link de seguimiento"
            type="text"
            name="trackingUrl"
            label="link de seguimiento"
            :rules="{ required: true, url: 'https://'}"
          />
          <ErrorMessage
            name="trackingUrl"
            class="text-sm text-red-500"
          />
        </div>
        <div
          v-else-if="selectedOption === 'withoutTrackingUrl'"
          class="flex flex-col mb-2"
        >
          <p class="mb-1 text-sm font-light md:text-base">
            Selecciona las fechas estimadas de despacho
          </p>
          <lokal-datepicker
            v-model="dates"
            class="mb-2"
            :auto-apply="true"
            :min-date="new Date()"
          />
          <Field
            v-model="initialDate"
            hidden
            :rules="{ required: true }"
            name="initialDate"
            label="fecha de despacho inicial"
          />
          <ErrorMessage
            name="initialDate"
            class="text-sm text-red-500"
          />
          <Field
            v-model="endDate"
            hidden
            :rules="{ required: true }"
            name="endDate"
            label="fecha límite de despacho"
          />
          <ErrorMessage
            name="endDate"
            class="text-sm text-red-500"
          />
          <lokal-tooltip
            main-label="Ingresa detalles del envío"
            position-options="left"
            information-classes="w-80"
            class="mb-1"
            button-classes="flex items-center space-x-1 font-light text-sm md:text-base"
            :information="trackingInformation"
          />
          <Field
            v-model="form.trackingInformation"
            as="textarea"
            label="información de seguimiento"
            placeholder="Ej: El despacho será realizado por nosotros en el viernes X entre las 2 y 4 pm."
            class="p-2 h-20 text-sm font-light focus:outline-none"
            :rules="{ required: true }"
            name="trackingInformation"
          />
          <ErrorMessage
            name="trackingInformation"
            class="text-sm text-red-500"
          />
        </div>
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          class="self-center"
        />
        <div
          v-else-if="showSubmitForm"
          class="flex flex-col items-center mt-auto"
        >
          <button
            class="p-2 mb-1 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md focus:outline-none md:text-base"
            type="submit"
          >
            Guardar
          </button>
          <button
            class="text-sm hover:text-red-500 hover:underline md:text-base"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </form>
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { Field, ErrorMessage, useForm } from 'vee-validate';
import makerShipmentDetailsApi from 'api/makerShipmentDetails';
import { useMutation } from 'vue-query';
import lokalTooltip from './shared/lokal-tooltip.vue';
import LokalDatepicker from './shared/lokal-datepicker.vue';
import SubOrderShipmentReminder from './sub-order-shipment-reminder.vue';

const props = defineProps<{
  subOrder: SubOrder,
  modelValue: boolean,
}>();

const reminderChecked = ref(false);

const dates = ref([] as Date[]);
const initialDate = computed(() => (dates.value?.[0] ? dates.value[0].toISOString().split('T')[0] : ''));
const endDate = computed(() => (dates.value?.[1] ? dates.value[1].toISOString().split('T')[0] : ''));
const form = ref({ trackingUrl: '', trackingInformation: '' });
const selectedOption = ref('');
const showSubmitForm = computed(() => (selectedOption.value === 'withTrackingUrl' && form.value.trackingUrl !== '') ||
    (selectedOption.value === 'withoutTrackingUrl' && form.value.trackingInformation !== ''));

const emit = defineEmits<{(e: 'reload:subOrder'): void, (e: 'update:modelValue', value: boolean): void}>();

const makerShipmentDetailQuery = useMutation(() => {
  if (selectedOption.value === 'withTrackingUrl') {
    form.value.trackingInformation = '';
  } else {
    form.value.trackingUrl = '';
  }

  return makerShipmentDetailsApi.create({ ...form.value, subOrderId: props.subOrder.id,
    initialDeliveryAt: initialDate.value, deadlineAt: endDate.value });
}, {
  onSuccess: () => {
    emit('reload:subOrder');
    emit('update:modelValue', false);
  },
},
);
const isLoading = computed(() => makerShipmentDetailQuery.isLoading.value);
const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  makerShipmentDetailQuery.mutate();
});

const trackingInformation = ref(`
  Incluye información como la fecha de despacho, nombre de carrier o cualquier otro detalle relevante. <br>
  Ejemplo: El despacho será realizado por nosotros en el viernes X entre las 2 y 4 pm.
`);
</script>
