<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Retiros</span>
      </p>
      <button
        v-if="props.maker.warehousePickupCondition === 'with_pickup'"
        class="flex items-center p-2 ml-auto text-sm text-lk-green bg-white rounded-md border border-lk-green focus:outline-none md:p-3 md:space-x-2 md:text-base"
        :class="{ 'bg-lk-green text-white': showCreatePickupDialog,
                  'hover:bg-lk-green hover:text-white': !showCreatePickupDialog }"
        @click="showCreatePickupDialog = !showCreatePickupDialog"
      >
        <PlusIcon class="shrink-0 mr-2 w-3 h-3 md:w-4 md:h-4" />
        <span>Solicitar Retiro</span>
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <template v-else>
        <maker-pickup-list-card
          v-for="pickup in pickups"
          :key="pickup.id"
          :pickup="pickup"
        />
        <pagination-component
          v-if="!isLoading && pickups?.length > 0"
          :class="{ 'justify-end': isLoading }"
          :total-pages="totalPages"
          :current-page="currentPage"
          @set-new-page="(newPage: number) => currentPage = newPage"
        />
      </template>
    </div>
    <maker-create-pickup-dialog
      v-if="showCreatePickupDialog"
      v-model="showCreatePickupDialog"
      :maker="maker"
      @reload-pickups="async () => await pickupsQuery.refetch.value()"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useQuery } from 'vue-query';
import { PlusIcon } from '@heroicons/vue/24/outline';
import pickupsApi from 'api/pickups';
import makerCreatePickupDialog from './maker-create-pickup-dialog.vue';
import useCustomParseInt from './use/custom-parse-int';
import PaginationComponent from './shared/pagination-component.vue';
import makerPickupListCard from './maker-pickup-list-card.vue';

const props = defineProps<{
  maker: Maker;
}>();

const showCreatePickupDialog = ref(false);

const currentPage = ref(1);
const queryParams = computed(() => 'q[s]=created_at+desc');
const pickupsQuery = useQuery(
  ['enviamePickups', props.maker.id, currentPage, queryParams],
  () => pickupsApi.index(props.maker.id, currentPage.value, queryParams.value));
const isLoading = computed(() => pickupsQuery.isLoading.value);
const pickups = computed(() => pickupsQuery.data.value?.data.pickups as Pickup[]);
const totalResources = computed(() => useCustomParseInt(pickupsQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(pickupsQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));
</script>
