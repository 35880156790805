<template>
  <div class="flex flex-col w-full">
    <p class="mb-1 text-base font-semibold md:text-lg">
      Información de facturación
    </p>
    <div class="mb-2 w-full border-b border-gray-700" />
    <lokal-loading v-if="billingInformationIsLoading || addressesQuery.isLoading.value" />
    <template v-else-if="billingInformation?.id">
      <p class="text-sm md:text-base">
        Rut de empresa
      </p>
      <form-input
        v-model="businessRut"
        name="businessRut"
        class="mb-1 font-light"
        :rules="{ required: true, rut: true }"
        @change="updateBillingInformation({ businessRut })"
      />
      <p class="text-sm md:text-base">
        Razón social
      </p>
      <form-input
        v-model="socialReason"
        name="socialReason"
        class="mb-1 font-light"
        :rules="{ required: true }"
        @change="updateBillingInformation({ socialReason })"
      />
      <p class="text-sm md:text-base">
        Giro comercial
      </p>
      <form-input
        v-model="commercialBusiness"
        name="commercialBusiness"
        class="mb-1 font-light"
        :rules="{ required: true }"
        @change="updateBillingInformation({ commercialBusiness })"
      />
      <p class="text-sm md:text-base">
        Dirección de facturación
      </p>
      <lokal-generic-combobox
        v-model="address"
        :options-label="`Seleccione una dirección`"
        class="font-light"
        :multiple="false"
        compare-by="id"
        :local-search="false"
        :options="addresses"
        :get-option-key="(address: Address) => address.id"
        :get-option-label="(address: Address) => address.address"
        @update:model-value="() => updateBillingInformation({ addressId: address.id })"
      />
    </template>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useQuery, useMutation } from 'vue-query';
import { useI18n } from 'vue-i18n';
import billingInformationsApi from 'api/billing-informations';
import useMessageStore from 'stores/message-store';
import formInput from './shared/form-input.vue';
import useAddressesQuery from './queries/addresses-query';
import lokalGenericCombobox from './shared/lokal-generic-combobox.vue';

const props = defineProps<{
  maker: Maker;
}>();

const messageStore = useMessageStore();
const { t } = useI18n();

const computedMakerId = computed(() => props.maker.id);
const { addresses, addressesQuery } = useAddressesQuery(computedMakerId, computed(() => 'Maker'));
const billingInformationQuery = useQuery(
  ['billingInformation', props.maker.id], () => billingInformationsApi.get(props.maker.id),
);
const billingInformationIsLoading = computed(() => billingInformationQuery.isLoading.value);
const billingInformation = computed(() =>
  billingInformationQuery.data.value?.data.billingInformation as BillingInformation);

const socialReason = ref('');
const commercialBusiness = ref('');
const businessRut = ref('');
const address = ref({} as Address);

let definedInitialValues = false;
watch(billingInformation, (newValue) => {
  if (newValue?.id && !definedInitialValues) {
    socialReason.value = billingInformation.value.socialReason;
    commercialBusiness.value = billingInformation.value.commercialBusiness;
    businessRut.value = billingInformation.value.businessRut;
    address.value = billingInformation.value.address;
    definedInitialValues = true;
  }
});

const updateBillingInformationMutation = useMutation(
  (data: Record<string, string | number>) =>
    billingInformationsApi.update(billingInformation.value.id, data),
  {
    onSuccess: async (_, variables) => {
      let message = 'Datos actualizados correctamente:\n';
      Object.keys(variables).forEach((key) => {
        const keyName = t(`billingInformation.${key}`);
        message += `${keyName}: ${variables[key]}\n`;
      });
      messageStore.showMessage(message, 'success');
      await billingInformationQuery.refetch.value();
    },
  },
);

function updateBillingInformation(data:
  { socialReason?: string, commercialBusiness?: string, businessRut?: string, addressId?: number }) {
  updateBillingInformationMutation.mutate(data);
}
</script>
