<template>
  <div
    v-if="!subsidizedShippingsIsLoading && subsidizedShippings?.length > 0"
    class="flex flex-col shrink-0 self-start"
  >
    <div
      v-if="!subsidizedShippingsIsLoading && subsidizedShippings?.length > 0"
      class="flex flex-col shrink-0 w-full text-gray-700 bg-lk-light-purple rounded-md divide-y divide-white"
    >
      <button
        class="flex items-center py-2 px-3 sm:py-3 sm:px-4"
        @click="showSubsidizeShippingCards = !showSubsidizeShippingCards"
      >
        <div class="flex justify-center items-center p-1 mr-3 bg-white rounded-full">
          <TruckIcon class="w-5 h-5 text-lk-light-purple" />
        </div>
        <p class="text-sm text-left text-white sm:text-base md:flex-row md:mr-2">
          Descuento en despachos de hasta
          {{ useFormatCurrency(subsidizeAmount(maxSubsidizedShipping)) }}
        </p>
        <ChevronDownIcon
          class="shrink-0 ml-auto w-4 h-4 text-white"
          :class="{ 'rotate-180 transform duration-150 ease-in': showSubsidizeShippingCards,
                    'transform duration-150 ease-out': !showSubsidizeShippingCards }"
        />
      </button>
      <transition
        enter-active-class="transition duration-100"
        enter-from-class="-translate-y-2 opacity-50"
        enter-to-class="translate-y-0 transform opacity-100"
        leave-active-class="transition duration-100"
        leave-from-class="translate-y-0"
        leave-to-class="-translate-y-2 transform opacity-0"
      >
        <div
          v-if="showSubsidizeShippingCards"
          class="flex flex-col py-2 px-3 w-full text-sm font-light text-white sm:px-5 md:text-base"
        >
          <p
            v-for="subsidizedShipping in orderedSubsidizedShippings"
            :key="`subsidized-shipping-${subsidizedShipping.id}`"
            class="mr-2 text-left"
          >
            Despachos con descuento de hasta {{ useFormatCurrency(subsidizeAmount(subsidizedShipping)) }}
            para pedidos sobre {{ useFormatCurrency(subsidizedShipping.minimumAmountCents) }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { TruckIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import useFormatCurrency from './use/format-currency';
import useSubsidizedShippings from './use/subsidized-shippings';

const SUBSIDIZED_FACTOR = 2;

interface MakerShowDiscountsProps {
  maker: Maker;
}

const props = defineProps<MakerShowDiscountsProps>();

const computedMakerId = computed(() => props.maker.id);
const subsidizeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { subsidizedShippings, subsidizedShippingsIsLoading, orderedSubsidizedShippings } = useSubsidizedShippings(
  computedMakerId, subsidizeShippingsQueryParams, computed(() => true),
);
const maxSubsidizedShipping = computed(() =>
  orderedSubsidizedShippings.value?.[orderedSubsidizedShippings.value.length - 1]);

const showSubsidizeShippingCards = ref(false);

function subsidizeAmount(subsidizeShipping: SubsidizedShipping) {
  if (subsidizeShipping.kind === 'with_lokal') {
    return subsidizeShipping.subsidizedAmountCents * SUBSIDIZED_FACTOR;
  }

  return subsidizeShipping.subsidizedAmountCents;
}
</script>
