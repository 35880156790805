<template>
  <tr :class="{ 'border border-gray-500': originalQuantity !== quantity || newProduct }">
    <td class="shrink-0 p-2">
      <img
        :src="mainImageUrl"
        alt="product image"
        class="w-10 h-10 md:w-12 md:h-12"
      >
    </td>
    <td class="shrink-0 p-2 text-center">
      <p>
        {{ productName }}
      </p>
    </td>
    <td class="shrink-0 p-2 text-center">
      <Field
        v-model.number="quantity"
        type="number"
        class="p-2 w-14 text-xs text-center rounded-md border border-lk-light-gray focus:border-lk-green focus:outline-none md:w-20 md:text-sm"
        :name="inputName"
        :label="`cantidad de ${productName}`"
        :rules="{ required: true, min_value: 0}"
      />
    </td>
    <td class="shrink-0 p-2 w-14 text-center md:w-20">
      <div
        v-if="newProduct"
        class="p-1 rounded-md"
      >
        Nuevo producto
      </div>
      <span v-else-if="quantity === originalQuantity">-</span>
      <span
        v-else
        class=""
      >
        Cambió de {{ originalQuantity }} a {{ quantity }}
      </span>
    </td>
  </tr>
  <ErrorMessage
    v-slot="{ message }"
    as="tr"
    :name="`quantity-${uniqueId}`"
  >
    <td
      class="p-2 text-red-500"
      colspan="4"
    >
      {{ message }}
    </td>
  </ErrorMessage>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { Field, ErrorMessage } from 'vee-validate';

interface SubOrderAdvancePaymentDialogProps {
  modelValue: Record<string, Record<string, number>>
  maker: Maker
  subOrder: SubOrder
  uniqueId: string
  mainImageUrl: string
  productId: number
  productName: string
  originalQuantity: number
  newProduct?: boolean
}
const props = withDefaults(defineProps<SubOrderAdvancePaymentDialogProps>(), {
  newProduct: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: Record<string, Record<string, number>>): void,
}>();

const originalQuantity = ref(props.newProduct ? 0 : props.originalQuantity);
const quantity = ref(props.originalQuantity);
const inputName = ref(`quantity-${props.uniqueId}`);

function setNewQuantityData() {
  const data = {
    productId: props.productId,
    subOrderId: props.subOrder.id,
    originalQuantity: originalQuantity.value,
    newQuantity: quantity.value,
  };

  return { ...props.modelValue, [props.productId]: data };
}

watch(quantity, () => {
  if (quantity.value === originalQuantity.value) {
    const newData = { ...props.modelValue };
    delete newData[props.productId];
    emit('update:modelValue', newData);
  } else {
    emit('update:modelValue', setNewQuantityData());
  }
});

onMounted(() => {
  if (props.newProduct) {
    emit('update:modelValue', setNewQuantityData());
  }
});

onUnmounted(() => {
  if (props.newProduct) {
    const newData = { ...props.modelValue };
    delete newData[props.productId];
    emit('update:modelValue', newData);
  }
});
</script>
