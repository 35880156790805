import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  get(makerId: number) {
    const path = `/api/internal/makers/${makerId}/billing_information`;

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  update(billingInformationId: number, data: Partial<BillingInformation>) {
    const path = `/api/internal/billing_informations/${billingInformationId}`;

    return api({
      method: 'put',
      url: path,
      headers,
      data,
    });
  },
};
