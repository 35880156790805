import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  create(data: Partial<SubOrderProductModification>) {
    const path = '/api/internal/sub_order_product_modifications';

    return api({
      method: 'post',
      url: path,
      headers,
      data,
    });
  },
  bulkCreate(data: Partial<SubOrderProductModification>[]) {
    const path = '/api/internal/sub_order_product_modifications/bulk_create';

    return api({
      method: 'post',
      url: path,
      headers,
      data: { subOrderProductModifications: data },
    });
  },
};
