<template>
  <div class="flex w-full">
    <div class="flex flex-col items-center w-full text-gray-700">
      <h1
        v-if="!makerSlug"
        class="flex justify-between items-center mb-4 w-full font-serif text-2xl md:mb-6 md:text-4xl"
      >
        <span>Productos</span>
        <span class="text-sm md:text-base">{{ totalResources }}</span>
      </h1>
      <div class="flex flex-col mb-3 w-full md:mb-5">
        <product-list-display-filters
          v-if="isMobile || !!makerSlug"
          :maker-slug="makerSlug"
          :total-product-count="totalProductCount"
          class="mb-0 md:mb-2"
        />
        <search-bar
          v-model="searchText"
          name="searchText"
          placeholder="Busca por nombre de producto"
        />
      </div>
      <div
        v-if="!loading"
        class="grid grid-cols-2 gap-x-5 gap-y-10 mb-10 w-full sm:grid-cols-3 md:grid-cols-3 md:gap-x-6 md:gap-y-14 lg:grid-cols-4 lg:gap-x-8"
      >
        <product-card
          v-for="product in currentProducts"
          :key="product.id"
          :product="product"
          :shopping-button-enabled="true"
          :total-product-count="totalProductCount"
        />
      </div>
      <pagination-component
        v-if="!loading && currentProducts?.length"
        :total-pages="totalPages"
        :current-page="currentPage"
        @set-new-page="updateNewPage"
      />
      <div
        v-if="!loading && !currentProducts?.length"
        class="text-3xl text-gray-700"
      >
        Ups no encontramos nada ...
      </div>
      <lokal-loading
        v-if="loading"
        :loading="loading"
        class="mt-10"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch, computed, onMounted, inject } from 'vue';
import { orderBy } from 'lodash';
import { useQuery } from 'vue-query';
import { storeToRefs } from 'pinia';
import useProductFilterStore from 'stores/product-filter-store';
import useSessionStore from 'stores/user-store';
import useUrlMethods from './use/url-methods';
import ProductCard from './product-card.vue';
import PaginationComponent from './shared/pagination-component.vue';
import productsApi from '../api/products';
import LokalLoading from './shared/lokal-loading.vue';
import useCustomParseInt from './use/custom-parse-int';
import productListDisplayFilters from './product-list-display-filters.vue';
import SearchBar from './shared/search-bar.vue';
import useCleanAccents from './use/clean-accents';

interface ProductListDisplayInterface {
  makerSlug?: string,
  makerQueryParam?: string,
  totalProductCount?: number
}

const props = withDefaults(defineProps<ProductListDisplayInterface>(), {
  makerSlug: '',
  makerQueryParam: '',
  totalProductCount: 0,
});

const sessionStore = useSessionStore();
const currentAddress = computed(() => sessionStore.currentAddress);
const isMobile = inject('isMobile') as boolean;
const productFilterStore = useProductFilterStore();
const {
  selectedMakerIds, selectedCategoryIds, searchText,
  currentPage, onylRecommendedProducts, showOnlyProductsWithFreeShippingToCurrentAddress,
  selectedLabelIds, selectedCollectionIds, discountProducts, showOnlyMakersWithSubsidizedShipping,
} = storeToRefs(productFilterStore);
const orderByMakerPriority = computed(() => !!props.makerSlug);

const { getQueryParam } = useUrlMethods();

function setMakerCollectionCategoryAndLabelIds() {
  let baseQuery = '';
  if (selectedMakerIds.value.length) {
    baseQuery += selectedMakerIds.value.map((makerSlug) => (`q[maker_slug_in][]=${makerSlug}&`)).join('');
  }
  if (selectedCollectionIds.value.length) {
    baseQuery += selectedCollectionIds.value.map((collectionId) =>
      (`q[product_collections_collection_id_in][]=${collectionId}&`)).join('');
  }
  if (selectedCategoryIds.value.length) {
    baseQuery += selectedCategoryIds.value.map((categoryId) => (`q[categories_id_in][]=${categoryId}&`)).join('');
  }
  if (selectedLabelIds.value.length) {
    baseQuery += selectedLabelIds.value.map((labelId) => (`q[labels_id_in][]=${labelId}&`)).join('');
  }

  return baseQuery;
}

// eslint-disable-next-line max-statements
function queryParamsForNonSearchText() {
  let baseQuery = '';
  baseQuery += setMakerCollectionCategoryAndLabelIds();
  if (onylRecommendedProducts.value) {
    baseQuery += 'q[recommended_eq]=true&';
  }
  if (discountProducts.value) {
    // where('discounts.end_date >= ?', current_time).where('discounts.start_date <= ?', current_time)
    const now = new Date().toISOString();
    baseQuery += `q[discounts_start_date_lteq]=${now}&q[discounts_end_date_gteq]=${now}&`;
  }
  if (showOnlyProductsWithFreeShippingToCurrentAddress.value) {
    baseQuery += `with_free_shippings_to_region_id=${currentAddress.value.regionId}&`;
    baseQuery += `with_free_shippins_to_commune_id=${currentAddress.value.communeId}&`;
  }
  if (showOnlyMakersWithSubsidizedShipping.value) baseQuery += 'q[maker_subsidized_shippings_active_eq]=true&';

  return baseQuery;
}

const queryParams = computed(() => {
  const productDefaultQueryParams = 'q[status_eq]=2&q[s][]=status+desc&q[s][]=priority+asc&';
  let query = props.makerQueryParam ? `${props.makerQueryParam}` : productDefaultQueryParams;
  if (searchText.value) query += `search_text=${encodeURIComponent(useCleanAccents(searchText.value))}&`;
  query += queryParamsForNonSearchText();

  return new URLSearchParams(query).toString();
});

const productsQuery = useQuery(
  ['products', currentPage, queryParams],
  () => productsApi.active(currentPage.value, queryParams.value),
  { keepPreviousData: true },
);
const currentProducts = computed(() => {
  const shadowProducts = productsQuery.data.value?.data.products as Product[];
  if (orderByMakerPriority.value) {
    // note: status here is ASC because is string comparation of active with out_of_stock
    return orderBy(shadowProducts, ['status', 'makerPriority'], ['asc', 'asc']);
  }

  return shadowProducts;
});
const totalResources = computed(() => useCustomParseInt(productsQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(productsQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));
const loading = computed(() => productsQuery.isLoading.value || productsQuery.isPreviousData.value);

function updateNewPage(page: number) {
  currentPage.value = page;
}

function setPageFromQueryParam() {
  const page = getQueryParam('page');
  if (page) {
    currentPage.value = useCustomParseInt(page);
  }
}

onMounted(() => {
  setPageFromQueryParam();
});

watch(currentProducts, (newCurrentProducts) => {
  if (!!newCurrentProducts && newCurrentProducts?.length === 0) {
    currentPage.value = 1;
  }
});
</script>
