<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Facturas por despachos gratis</span>
      </p>
    </div>
    <div class="flex flex-col py-5 px-4 w-full h-full bg-white rounded-md md:py-5 md:px-10">
      <p class="mb-3 text-base md:text-lg">
        Detalles
      </p>
      <lokal-loading
        v-if="freeShippingInvoicesIsLoading"
        :loading="freeShippingInvoicesIsLoading"
      />
      <table
        v-else
        class="text-sm table-auto md:text-base"
      >
        <thead class="bg-gray-100">
          <tr>
            <th class="shrink-0 p-2 border border-gray-300">
              Mes
            </th>
            <th class="shrink-0 p-2 border border-gray-300">
              Año
            </th>
            <th class="shrink-0 p-2 border border-gray-300">
              Factura
            </th>
            <th class="p-2 border border-gray-300">
              Estado de pago
            </th>
            <th class="shrink-0 p-2 border border-gray-300">
              Excel
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="freeShippingInvoice in freeShippingInvoices"
            :key="`free-shipping-invoices-${freeShippingInvoice.id}`"
          >
            <tr class="text-center">
              <td class="p-2 border border-gray-300">
                {{ freeShippingInvoice.month }}
              </td>
              <td class="p-2 border border-gray-300">
                {{ freeShippingInvoice.year }}
              </td>
              <td class="p-2 border border-gray-300">
                <a
                  v-if="!!freeShippingInvoice.invoiceUrl"
                  :href="freeShippingInvoice.invoiceUrl"
                  target="_blank"
                >
                  Factura #{{ freeShippingInvoice.invoiceNumber }}
                </a>
              </td>
              <td class="p-2 border border-gray-300">
                {{ t(`freeShippingInvoice.paymentStatus.${freeShippingInvoice.paymentStatus}`) }}
              </td>
              <td class="p-2 border border-gray-300">
                <downloadable-files-form
                  :action="`/makers/${props.maker.id}/free_shipping_excel?${queryParam(freeShippingInvoice)}`"
                  label="Descargar"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import freeShippingInvoicesApi from 'api/free-shipping-invoices';
import { useI18n } from 'vue-i18n';
import downloadableFilesForm from './shared/downloadable-files-form.vue';

const props = defineProps<{
  maker: Maker
}>();

const { t } = useI18n();

function queryParam(freeShippingInvoice: FreeShippingInvoice) {
  return `month=${freeShippingInvoice.month}&year=${freeShippingInvoice.year}`;
}

const freeShippingInvoicesQuery = useQuery(
  ['freeShippingInvoices', props.maker.id],
  () => freeShippingInvoicesApi.get(props.maker.id),
);
const freeShippingInvoicesIsLoading = computed(() => freeShippingInvoicesQuery.isLoading.value);
const freeShippingInvoices = computed(() =>
  freeShippingInvoicesQuery.data.value?.data.freeShippingInvoices as FreeShippingInvoice[]);
</script>
