<template>
  <div
    ref="dropdownContainer"
    class="relative"
  >
    <button
      :class="buttonClasses"
      type="button"
      @click.stop.prevent="showTooltip = !showTooltip"
      @mouseover="isMobile ? null : showTooltip = true"
      @mouseleave="isMobile ? null : showTooltip = false"
    >
      <slot name="custom-content">
        <p>
          {{ mainLabel }}
        </p>
        <QuestionMarkCircleIcon class="shrink-0 w-4 h-4" />
      </slot>
    </button>
    <div
      v-if="showTooltip"
      :class="[arrowPositionClasses, arrowColorClasses]"
      class="absolute z-10 border border-x-8 border-t-0 border-b-8 border-x-transparent border-solid"
    />
    <div
      v-if="showTooltip"
      ref="tooltipContent"
      class="flex overflow-y-auto absolute z-10 flex-col p-2 mt-2 max-h-96 text-sm text-white rounded-md divide-gray-100 focus:outline-none origin-center"
      :class="[defaultInformationClasses, informationColorClasses, informationPositionClasses]"
    >
      <p class="flex flex-col">
        <span
          v-for="(info, index) in arrayOfInformation"
          :key="index"
        >
          {{ info }}
        </span>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, inject, nextTick } from 'vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';

interface LokalDropDownProps {
  information: string;
  mainLabel?: string;
  informationClasses?: string;
  arrowColorClasses?: string;
  informationColorClasses?: string;
  buttonClasses?: string;
}

const props = withDefaults(defineProps<LokalDropDownProps>(), {
  mainLabel: '',
  informationClasses: '',
  buttonClasses: 'flex z-0 flex-col items-center space-x-1 focus:outline-none md:flex-row md:space-x-2',
  arrowColorClasses: 'border-lk-light-purple',
  informationColorClasses: 'bg-lk-light-purple',
});

const isMobile = inject('isMobile');
const showTooltip = ref(false);
const arrayOfInformation = ref(props.information.split('<br>'));
const dropdownContainer = ref<HTMLElement | null>(null);
const tooltipContent = ref<HTMLElement | null>(null);

const defaultInformationClasses = ref('w-52'); // Default tooltip width
const informationPositionClasses = ref('');
const arrowPositionClasses = ref('');

const dropdownContainerRect = computed(() => dropdownContainer.value?.getBoundingClientRect());
const tooltipContentRect = computed(() => tooltipContent.value?.getBoundingClientRect());
const dropdownContainerLeft = computed(() => dropdownContainerRect.value?.left);

const tooltipWidth = ref(0);

const HALF = 2;

function setDefaultPositionValues() {
  // Default tooltip width is set to w-52 (13rem, ~208px)
  const defaultWidth = 208;
  if (tooltipWidth.value < defaultWidth) {
    tooltipWidth.value = defaultWidth;
    if (tooltipContent.value) tooltipContent.value.style.width = `${tooltipWidth.value}px`;
  }
  // Default alignment is center
  informationPositionClasses.value = 'left-1/2 -translate-x-1/2';
  arrowPositionClasses.value = 'left-1/2 -translate-x-1/2';
}

function setPosition() {
  if (dropdownContainerRect.value && tooltipContentRect.value) {
    const iconPosition = dropdownContainerLeft.value || 0;
    const windowWidth = window.innerWidth;
    tooltipWidth.value = tooltipContentRect.value.width;
    setDefaultPositionValues();

    if (iconPosition < tooltipWidth.value / HALF) {
      // If the tooltip overflows to the left, align to the left
      informationPositionClasses.value = 'left-0';
      arrowPositionClasses.value = 'left-1';
    } else if (iconPosition + tooltipWidth.value / HALF > windowWidth) {
      // If the tooltip overflows to the right, align to the right
      informationPositionClasses.value = 'right-0';
      arrowPositionClasses.value = 'right-1';
    }
  }
}

function updatePosition() {
  nextTick(() => {
    setPosition();
  });
}

watch(() => showTooltip.value, (newValue) => {
  if (newValue) {
    updatePosition();
  }
});

watch(() => props.information, () => {
  arrayOfInformation.value = props.information.split('<br>');
});

onMounted(() => {
  updatePosition();
});
</script>
